<template>
  <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange"  v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="名称或账号" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="启用状态" v-model="listQuery.condition.enabled">
          <el-option v-for="item in stateOptions" :key="item.name" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
      </el-row>
    </template>
    <template #columns>
      <el-table-column min-width="150px" label="账号" sortable="custom" property="account" align="center">
        <template #default="scope">
          <span>{{scope.row.account}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="姓名" align="center" sortable="custom" property="name">
        <template  #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="手机号" align="center" property="phone">
        <template  #default="scope">
          <span>{{scope.row.phone}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="部门" align="center" property="dept.name">
        <template  #default="scope">
          <span>{{scope.row.dept.name}}</span>
        </template>
      </el-table-column>
      <el-table-column class-name="status-col" align="center" label="启用状态" width="100px">
        <template  #default="scope">
          <el-tag :type="showFilter(scope.row.enabled)">{{scope.row.enabled && scope.row.enabled==='YES' ? '启用' : '停用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="350px" class-name="fixed-width">
        <template  #default="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)" v-waves>编辑</el-button>
          <el-button size="mini" :type="showButtonFilter(scope.row.enabled)" @click="handleState(scope.row)" v-waves>{{scope.row.enabled && scope.row.enabled==='YES'? '停用' : '启用'}}</el-button>
          <el-button size="mini" type="primary" @click="handleChangeUserPassword(scope.row)" v-waves>重置密码</el-button>
          <el-button type="danger" size="mini" @click="deleteCustomer(scope.row)" v-waves>删除</el-button>
        </template>
      </el-table-column>
    </template>

    <!-- <el-dialog :title="changePassword.title" v-model="changePassword.show" width="40%" @close="handleChangePasswordDialogClose">
      <el-form size="mini" ref="changePasswordForm" :model="changePassword.formData" :rules="changePassword.rules" label-width="120px" status-icon>
        <el-form-item label="新密码" prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="changePassword.formData.password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input type="password" placeholder="请再次输入密码" v-model="changePassword.formData.checkPassword" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer class="dialog-footer">
        <el-button size="mini" @click="changePassword.show = false">取消</el-button>
        <el-button size="mini" type="primary" @click="handleDoChangeUserPassword" :loading="changePassword.loading">{{changePassword.loading ? '提交中' : '确定'}}</el-button>
      </template>
    </el-dialog> -->
  </list-condition-template>
</template>

<script>
import customerApi from '@/apis/customer/customer'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      rows: null,
      total: 0,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      stateOptions: [
        {
          name: '启用',
          value: 1
        },
        {
          name: '停用',
          value: 0
        }
      ],
      changePassword: {
        formData: {
          password: '',
          checkPassword: '',
          accountId: '',
          userId: undefined
        },
        title: '修改密码',
        show: false,
        loading: false,
        rules: {
          password: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('请输入密码'))
                } else if (value.length < 6 || value.length > 20) {
                  callback(new Error('密码长度需在6到20之间'))
                } else {
                  if (this.changePassword.formData.checkPassword !== '') {
                    this.$refs.changePasswordForm.validateField(
                      'checkPassword'
                    )
                  }
                  callback()
                }
              }
            }
          ],
          checkPassword: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('请再次输入密码'))
                } else if (value.length < 6 || value.length > 20) {
                  callback(new Error('密码长度需在6到20之间'))
                } else if (value !== this.changePassword.formData.password) {
                  callback(new Error('两次输入密码不一致'))
                } else {
                  callback()
                }
              }
            }
          ]
        }
      }
    }
  },
  filters: {
    /**
     * 表格行显示状态过滤
     * @param {*} show 显示状态
     */

  },
  created () {
    this.getList()
  },
  methods: {
    /**
     * 加载数据
     */
    getList () {
      customerApi
        .getQuery(this.listQuery, 'page')
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    showFilter (show) {
      return show && show ==='YES' ? 'success' : 'danger'
    },
    /**
     * 表格按钮显示状态过滤
     * @param {*} show 显示状态
     */
    showButtonFilter (show) {
      return !show || show ==='NO' ? 'success' : 'danger'
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList()
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.getList()
    },
    /**
     * 新增按钮点击处理
     */
    handleCreate () {
      this.$router.pushTo(this.$router.routesConfig.customerNew)
    },
    /**
     * 修改按钮点击处理
     * @param {*} row 参数
     */
    handleUpdate (row) {
      this.$router.pushTo(this.$router.routesConfig.customerEdit, row.id)
    },
    /**
     * 启用、停用按钮点击处理
     * @param {*} row 参数
     */
    handleState (row) {
      var isEnabled = ''
      if(row.enabled && row.enabled === 'YES'){
        isEnabled = false
      }else{
        isEnabled = true
      }
      customerApi
        .setState({ accountId: row.accountId, enabled: isEnabled })
        .then(response => {
          this.getList()
        })
    },
    /**
     * 重置密码按钮点击处理
     * @param {*} row 参数
     */
    handleChangeUserPassword (row) {
      this.$confirm('重置后会恢复初始密码,确定要重置该用户的密码吗？', '警告', {
        type: 'warning'
      }).then(() => {
        customerApi.resetPassword({ id: row.accountId }).then(response => {
          if (response.data) {
            this.$message({
              title: '成功',
              message: `密码重置成功`,
              type: 'success'
            })
          }
        })
      })
    },
    /**
     * 重置密码
     */
    handleDoChangeUserPassword () {
      this.$refs.changePasswordForm.validate().then(pass => {
        if (pass) {
          this.changePassword.loading = true
          customerApi
            .resetPassword({
              id: this.changePassword.formData.accountId,
              newPassword: this.changePassword.formData.password
            })
            .then(response => {
              this.changePassword.loading = false
              this.changePassword.show = false
              this.changePassword.formData.userId = undefined
              this.$message({
                title: '成功',
                message: `密码重置成功`,
                type: 'success'
              })
            })
            .catch(response => {
              this.changePassword.loading = false
            })
        }
      })
    },
    /**
     * 删除按钮点击处理
     * @param {*} row 参数
     */
    deleteCustomer (row) {
      this.$confirm('请确保用户没有正在进行中的流程，删除后将无法恢复，确定要删除该用户吗？', '警告', {
        type: 'warning'
      }).then(() => {
        customerApi.delete(row.id).then(res => {
          if (res.data) {
            this.handleFilter()
          } else {
            this.$message.error({
              title: '发生错误',
              message: res.msg === '' ? '删除用户失败，请稍后重试！！！' : res.msg,
              duration: 0,
              position: 'bottom-right'
            })
          }
        })
      })
      this.$router.replace('/org/customer/list')
    },
    /**
     * 重置密码对话框关闭事件处理
     */
    handleChangePasswordDialogClose () {
      this.$refs.changePasswordForm.resetFields()
    }
  }
}
</script>
